<template>
    <div class="footer_bot">
        <div class="main">
            <div class="row-content">
                <div>
                    <ul>
                        <li v-for="(item, index) in firstArr" :key="index">
                            <a v-if="!item.id" :style="{fontWeight:index===0?'bold !important':400}">{{item.name}}</a>
                            <a v-if="item.id" :href="'/course?cateId='+item.id" :style="{fontWeight:index===0?'bold !important':400}">{{item.name}}</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li >
                            <a style="fontWeight:bold">解决方案</a>
                        </li>
                        <li v-for="(item, index) in daohangConfig.jjfa" :key="index">
                            <a  style="fontWeight:400" @click="goOpen(item.link)">{{item.name}}</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li v-for="(item, index) in thirdArr" :key="index">
                            <a v-if="!item.href" :style="{fontWeight:index===0?'bold !important':400}">{{item.name}}</a>
                            <a v-if="item.href" :href="item.href" :style="{fontWeight:index===0?'bold !important':400}">{{item.name}}</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li v-for="(item, index) in fourthArr" :key="index">
                            <a v-if="!item.href" :style="{fontWeight:index===0?'bold !important':400}">{{item.name}}</a>
                            <a v-if="item.href" :href="item.href" :style="{fontWeight:index===0?'bold !important':400}">{{item.name}}</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <img src="../assets/shouguanqrcode.png"><br>
                    <div class="desc">首冠教育微信公众号</div>
                </div>
                <div>
                    <img src="../assets/duxueqrcode.png"><br>
                    <div class="desc">首冠督学公众号</div>
                </div>
                <div style="padding-top:36px;">
                    电话：+86 010-64508600<br><br>邮箱:oneplusx@shouguan.cn
                </div>
            </div>

            <div class="footer-line"/>

            <div class="copy">
                Copyright © 2006-2020 首冠教育, All Rights Reserved. 京公网安备 11011302001876号 <a style="font-size:13px" href="https://beian.miit.gov.cn/" target="_blank">京ICP备2021026270号</a> 呼叫业务B2-20183766
            </div>
            <div class="copy">
                地址：中国北京丰台区南三环西路16号3号楼11层. 邮编：100068
            </div>
            <a href="https://uweb.umeng.com/v1/login.php?siteid=1280277974" target="_blank">
                <div class="copy">
                    站长统计
                </div>
            </a>
            
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: mapState([
        'userInfo',
        'daohangConfig',
    ]),
    data() {
        return {
            firstArr:[
            ],
            secondArr:[
                {name:'解决方案'},
                {name:'1+X证书考试课程'},
                {name:'智能会计数据采集及应用'},
                {name:'大数据财务分析'},
                {name:'大数据财务决策'},
                {name:'phthon在财务中的应用'},
            ],
            thirdArr:[
                {name:'服务'},
                {name:'职业认证'},
                {name:'技能竞赛',href:'/techCom'},
                {name:'考试系统'},
                {name:'实习就业',href:'/partnerQy'},
            ],
            fourthArr:[
                {name:'了解首冠'},
                {name:'公司介绍',href:'/aboutus'},
                {name:'荣誉资质',href:'/aboutus?index=1'},
                {name:'加入我们',href:'/aboutus?index=2'},
                {name:'联系方式',href:'/aboutus?index=3'},
            ]
        }
    },
    async mounted() {
        const res = await this.$http.get('/api/course/getCourseCate');
        if(res){
            res.unshift({name:'企业级课程'});
            this.firstArr = res;
        }
    },
    methods: {
        goOpen(url){
            if(Object.hasOwnProperty.call(this.userInfo,'role_id')&&Number(this.userInfo.role_id)!==1){
                window.open(url);
            }else if(!Object.hasOwnProperty.call(this.userInfo,'role_id')){
                this.$router.push('/login');
            }else{
                this.$toast('企业用户暂无权限跳转');
            }
        }
    },
}
</script>
<style lang="scss" scoped>
a,a:link { cursor:pointer; color: #FFF;text-decoration: none;}
a:hover{ text-decoration: none; color:#04407D;}
.footer_bot{
    padding: 30px 0;
    background: #242636;
    color: #fff;
    box-sizing: border-box;
    position: relative;
    font-family: "Microsoft YaHei","微软雅黑" !important;
}
.main{
    width: 1162px;
    box-sizing: border-box;
    margin: auto;
    .row-content{
        width:1136px;
        margin: auto;
        margin-bottom: 90px;
        display: flex;
        justify-content: space-between;
        ul li{
            margin-bottom: 15px;
            font-size: 16px;
        }
        img{
            width: 125px;
            height: 125px;
        }
        .desc{
            text-align: center;
            margin-top: 10px;
        }
    }
    .site {
        padding-bottom: 25px;
        span {
            padding: 0 20px;
        }
    }
    .ba {
        font-size: 14px;
        color: #66809a;
    }
    .copy {
        width: 100%;
        padding-bottom: 10px;
        letter-spacing: 1px;
        font-size: 13px;
        line-height: 20PX;
        text-align: center;
    }
}

.footer-line{
    width: 1920px;
    height: 1px;
    background: #fff;
    margin: 41px 0 55px 0;
    left: calc(50% - 960px);
    position: absolute;
    bottom: 108px;
}

</style>
