<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script> 
export default {
    async beforeMount() {
      this.$http.get('/api/index/getGlobalConfig').then(res => {
          this.$store.commit('UPDATE_GLOBALCONFIG',res);
          document.getElementsByTagName('html')[0].style.filter = res['website_backgroud'].content;
      })
      this.$store.dispatch('UPDATE_INDEXCONFIG',this.$http);
      // this.$http.get('/api/index/getIndexConfig').then(res => {
      //     this.$store.commit('UPDATE_DAOHANGCONFIG',res);
      // })
      if(localStorage.getItem('shouguancloud_token')){
        this.$store.dispatch('UPDATE_USERINFO',this.$http);
      }
    },
    mounted() {
    },
}
</script>

<style>
#app {
  font-family: "Microsoft YaHei","微软雅黑" !important;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0px;
  box-sizing: border-box;
  min-width: 1280px;
  height: 100vh;
}
</style>
