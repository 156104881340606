const filters = {
    formatHomePageNewsContent(str){
        console.log(str);
        if(str.length>200){
            return str.splice(0,200) + '...';
        }else{
            return str;
        }
    },
    filterStr(str, len){
        if(str.length>len){
            return str.slice(0,len) + '...';
        }else{
            return str;
        }
    },
    transform(tranvalue) {
        let str = '';
        try {
            let i = 1;
            const dw2 = ['', '万', '亿']; // 大单位
            const dw1 = ['十', '百', '千']; // 小单位
            const dw = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; // 整数部分用
            // 转换整数
            let k1 = 0; // 计小单位
            let k2 = 0; // 计大单位
            let sum = 0;
            const len = tranvalue.length; // 整数的长度
            for (i = 1; i <= len; i++) {
                const n = tranvalue.charAt(len - i); // 取得某个位数上的数字
                let bn = 0;
                if (len - i - 1 >= 0) {
                    bn = tranvalue.charAt(len - i - 1); // 取得某个位数前一位上的数字
                }
                sum += Number(n);
                if (sum !== 0) {
                    str = dw[Number(n)].concat(str); // 取得该数字对应的大写数字，并插入到str字符串的前面
                    if (n === '0') {
                        sum = 0;
                    }
                }
                if (len - i - 1 >= 0) {
                    // 在数字范围内
                    if (k1 !== 3) {
                        // 加小单位
                        if (bn !== 0) {
                            str = dw1[k1].concat(str);
                        }
                        k1++;
                    } else {
                        // 不加小单位，加大单位
                        k1 = 0;
                        const temp = str.charAt(0);
                        if (temp === '万' || temp === '亿') {
                            // 若大单位前没有数字则舍去大单位
                            str = str.substr(1, str.length - 1);
                        }
                        str = dw2[k2].concat(str);
                        sum = 0;
                    }
                }
                if (k1 === 3) {
                    // 小单位到千则大单位进一
                    k2++;
                }
            }
        } catch (e) {
            return '';
        }
        return str;
    },
    formatQuestionType(val){
        const arr = ['单选题','多选题','判断题','填空题','主观题'];
        return arr[Number(val)];
    }
};

export default filters;
