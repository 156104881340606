import Vue from 'vue'
import App from './App.vue'
import { MessageBox, Message, Loading, Tree, Input, Pagination, Scrollbar, Rate, Menu, MenuItem, Dialog, Notification } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
import store from './store'
import filters from './filter'
import header from './components/header.vue'
import footer from './components/footer.vue'
import noAuth from './components/noAuth.vue'
import HeaderTitle from './ziyuan/header.vue';
import './assets/iconfont/iconfont.css'
import './assets/reset.css';
import { wrapResponse, getJSON, postJSON } from './assets/utils/http'
import './components/toast/index.css';
import Toast from './components/toast/index';
Vue.use(Toast);

// const baseUrl = 'http://47.95.146.124:8888/app/mock/5';
// const baseUrl = 'http://cfda.san315.com';
const baseUrl = '';
Vue.prototype.$http = {
  get:(url,params={}) => {
    url = baseUrl + url;
    return wrapResponse(getJSON(url, params));
  },
  post:(url,params={},options={}) => {
    url = baseUrl + url;
    return wrapResponse(postJSON(url, params,options));
  },
}
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;


Vue.use(Loading.directive);
// Vue.prototype.$loading = Loading.service;

Vue.config.productionTip = false
for(const key in filters){
  Vue.filter(key,filters[key]);
}

Vue.component('HeaderTitle', HeaderTitle);
Vue.component(Dialog.name, Dialog);
Vue.component(Menu.name, Menu);
Vue.component(MenuItem.name, MenuItem);
Vue.component(Tree.name, Tree);
Vue.component(Input.name, Input);
Vue.component(Pagination.name, Pagination);
Vue.component('el-scollbar', Scrollbar);
Vue.component(Rate.name, Rate);
Vue.component('Header',header)
Vue.component('Footer',footer)
Vue.component('NoAuth',noAuth)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
