import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/',
            component: () => import('./homePage/app.vue'),
        },
        {
            path: '/login',
            component: () => import('./registerAndLogin/login.vue'),
        },
        {
            path: '/reset',
            component: () => import('./registerAndLogin/reset.vue'),
        },
        {
            path: '/register',
            component: () => import('./registerAndLogin/register.vue'),
        },
        {
            path: '/code',
            component: () => import('./registerAndLogin/code.vue'),
        },
        {
            path: '/partnerYx',
            component: () => import('./qiyeyuanxiao/yuanxiao.vue'),
        },
        {
            path: '/partnerQy',
            component: () => import('./qiyeyuanxiao/qiye.vue'),
        },
        {
            path: '/planDetail',
            component: () => import('./homePage/jiejuefanganDetail.vue'),
        },
        {
            path: '/hezuo',
            component: () => import('./homePage/hezuoDetail.vue'),
        },
        {
            path: '/planDetailD',
            component: () => import('./homePage/jiejuefanganDetail2.vue'),
        },
        {
            path: '/info',
            component: () => import('./infoList/list.vue'),
        },
        {
            path: '/detail',
            component: () => import('./infoList/detail.vue'),
        },
        {
            path: '/studentResources',
            component: () => import('./ziyuan/xuesheng.vue'),
        },
        {
            path: '/enterpriseResources',
            component: () => import('./ziyuan/qiye.vue'),
        },
        {
            path: '/demandRegistration',
            component: () => import('./ziyuan/demandRegistration.vue'),
        },
        {
            path: '/schoolResources',
            component: () => import('./ziyuan/yuanxiao.vue'),
        },
        {
            path: '/course',
            component: () => import('./course/app.vue'),
        },
        {
            path: '/courseDetail',
            component: () => import('./course/detail.vue'),
        },
        {
            path: '/learningCourse',
            component: () => import('./course/learning.vue'),
        },
        {
            path: '/exam',
            component: () => import('./exam/app.vue'),
        },
        {
            path: '/simulationPractice',
            component: () => import('./simulationPractice/app.vue'),
        },
        {
            path: '/techCom',
            component: () => import('./technicalCompetition/index.vue'),
        },
        {
            path: '/infoList',
            component: () => import('./technicalCompetition/list.vue'),
        },
        {
            path: '/infoDetail',
            component: () => import('./technicalCompetition/detail.vue'),
        },
        {
            path: '/desc',
            component: () => import('./technicalCompetition/desc.vue'),
        },
        {
            path: '/bData',
            component: () => import('./bData/index.vue'),
        },
        {
            path: '/aboutus',
            component: () => import('./aboutus/index.vue'),
        },
        {
            path: '/aaca',
            component: () => import('./jingtai/aaca.vue'),
        },
        {
            path: '/glhjs',
            component: () => import('./jingtai/glhjs.vue'),
        },
        // 在线报名
        {
            path: '/onlineEnroll',
            component: () => import('./enroll.vue'),
        },
        // 汇报ppt
        {
            path: '/reportPPT',
            component: () => import('./reportPPT.vue'),
        },
    ],
});
router.beforeEach((to, from, next) => {
    // const needVerLoginPageArr = ['/course','/courseDetail','/learningCourse','/simulationPractice'];
    const needVerLoginPageArr = ['/course','/courseDetail','/learningCourse','/onlineEnroll','/reportPPT'];
    const toPath = to.path.split('?')[0];
    if(to.path === '/register'&&localStorage.getItem('shouguancloud_token')){
        next('/');
    }
    else if(!localStorage.getItem('shouguancloud_token')&&needVerLoginPageArr.indexOf(toPath)>-1){
        next('/login');
    }
    else{
        next();
    }
    
});
export default router;
