<template>
    <div class="tit-mod-secs clearfix title-header" >
            <h3 class="fl font-s16" style="color:#3884D3">{{title}}</h3>
    </div>
</template>
<script>
export default {
    props:{
        title:{
            type:String,
            default:() => '学生资源库'
        }
    }
}
</script>
<style lang="scss" scoped>
.title-header{
    width: 1200px;
    margin: auto;
    height: 42px;
    line-height: 42px;
    background: #F4F4F4;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
}
.tit-mod-secs{
    padding-top: 6px !important;
}
.tit-mod-secs h3{

}
</style>
