<template>
    <div class="header">
        <div class="content">
            <img class="logo" :src="globalConfig.logo.image" @click="goHome">
            <div style="display: inline-block;vertical-align: middle;">
                <ul class="title-list">
                    <li v-for="(item, index) in titleArr" :key="index">
                        <a v-if="index!==5&&index!==3&&index!==2" class="xxxx" :href="item.url">{{item.name}}</a>
                        <a class="xxxx" v-if="index===2">{{item.name}}</a>
                        <div v-if="index===2" class="naverj" style="display: none; opacity: 1;">
                            <a v-for="(item, sindex) in daohangConfig.jjfa" :key="sindex" :title="item.name" @click="goOpen(item.link,index)" > {{item.name}} </a>
                        </div> 

                        <a class="xxxx" v-if="index===3">{{item.name}}</a>
                        <div v-if="index===3" class="naverj" style="display: none; opacity: 1;">
                            <a href="/bData" title="1+X大数据财务分析"> 1+X大数据财务分析 </a>
                            <a href="/glhjs" title="管理会计师CNMA"> 管理会计师CNMA </a>
                            <a href="/aaca" title="国际注册会计师ICPA"> 国际注册会计师ICPA </a>
                        </div> 

                        <a class="xxxx" v-if="index===5">{{item.name}}</a>
                        <div v-if="index===5" class="naverj" style="display: none; opacity: 1;">
                            <a v-for="(item, sindex) in daohangConfig.exam_system" :key="sindex" :title="item.name" @click="goOpen(item.link,index)"> {{item.name}} </a>
                            <!-- <a href="/simulationPractice" title="模拟实操"> 模拟实操 </a> -->
                            <!-- <a href="/exam" title="在线考试"> 在线考试 </a> -->
                        </div> 
                    </li>
                </ul>
            </div>
            
            <div v-if="!(Object.hasOwnProperty.call(userInfo,'username'))" class="login-register">
                <a href="/login" style="color:#5da9e4">注册/登录</a>
            </div>
            <div class="user_info"> 
                <div v-if="(Object.hasOwnProperty.call(userInfo,'username'))" class="name">
                    {{userInfo.username}}
                    <div class="list">
                        <ul>
                            <li @click="loginOut">退出登录</li>
                        </ul>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: mapState([
        'userInfo',
        'globalConfig',
        'daohangConfig',
    ]),
    data() {
        return {
            titleArr:[
                {name:'平台首页',url:'/'},
                {name:'课程资源',url:'/course'},
                {name:'财经实训',url:'/code'},
                {name:'职业认证',url:'/code'},
                {name:'技能竞赛',url:'/techCom'},
                {name:'考试系统',url:'/exam?type=0'},
                {name:'实习就业',url:'/partnerQy'},
            ]
        }
    },
    methods: {
        async loginOut(){
            await this.$http.get('/api/user/logout');
            this.$store.commit('LOGIN_OUT',this.$toast);
        },
        goHome(){
            window.location.href="/";
        },
        goOpen(url, index){
            if(index === 5){
                window.open(url);
                return false;
            }
            if(Object.hasOwnProperty.call(this.userInfo,'role_id')&&Number(this.userInfo.role_id)!==1){
                window.open(url);
            }else if(!Object.hasOwnProperty.call(this.userInfo,'role_id')){
                this.$router.push('/login');
            }else{
                this.$toast('企业用户暂无权限跳转');
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.title-list li:hover .naverj{display: block !important;}
.naverj{ position: absolute; top:49px; border-radius:10PX; padding:10PX 0; background-color: rgba(232,232,242,0.9); border-bottom:3px solid #405d9e; margin-left:-30PX; display: none; z-index:9999; 
    box-sizing: border-box;}
.naverj a {padding-left: 28px;font-size: 16px; color: #0e196b; height:45px; line-height:45px;display:block;min-width: 180px;}
.naverj a:hover{ color: #fff !important; background:#224899; text-decoration: none; }
.content{
    width:1200px;
    margin: auto;
    height: 85px;
    // padding: 20px 0;
}

.logo{
    height: 65px;
    vertical-align: middle;
    margin-right: 40px;
}
.title-list{
    vertical-align: middle;
    display: flex;
    font-size: 16px;
    li{
        margin-right:38px;
        color:#0e196b;
        position: relative;
        height: 80px;
        line-height: 80px;
    }
}

.login-register{
    display: inline-block;
    width: 110px;
    height: 28px;
    border: 1px solid #5da9e4;
    border-radius: 24px;
    line-height: 28px;
    text-align: center;
    color: #5da9e4;
    margin-left: 20px;
    cursor: pointer;
}
.user_info{
    width:85px;display: inline-block;margin-left: 20px;
    position: relative;color: #5da9e4;font-size:16px;
    vertical-align: middle;
    .list{
        display: none;
        position: absolute;
        top: 15px;
        left:5px;
        z-index: 999;
        li{
            width:100px;
            text-align: center;
        }
    }
    .name:hover .list{
        display: block;
        background: #fff;
        cursor: pointer;
        padding:5px;
        box-shadow:0 -2px 4px rgba(0,0,0,.2);
        li:hover{
            background:#ff7700;
            color:#fff;
        }
    }
}
.xxxx{
    color: #5da9e4 !important;
}
</style>
