<template>
    <div style="text-align:center;" :style="{height:innerHeight-314+'px'}">
        <div class="blank40"/>
        <div class="blank40"/>
        <div class="blank40"/>
        <span>暂无权限</span>
    </div>
</template>
<script>
export default {
    data() {
        return {
            innerHeight:''
        }
    },
    mounted() {
        this.innerHeight = window.innerHeight;
    },
}
</script>
