import axios from 'axios';
import qs from 'qs';
import { merge } from 'lodash';
import querystring from 'query-string';
function handleUrl(baseUrl, url) {
    // 处理url前缀可能不一致
    let _url = url.replace(baseUrl, '');
    if (_url.indexOf('/') !== 0) {
        _url = '/' + _url;
    }
    return _url;
}
function getQsObj() {
    if(window.location.href.indexOf('?') === -1){
        return {};
    }
    const search = querystring.parse(window.location.href.replace(/.*\?/, ''));
    return search;
}


const pending = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
const cancelToken = axios.CancelToken;
const removePending = config => {
    if(config&&config.params&&config.data&&config.baseURL&&config.url&&config.method){
        // for (const i in pending) {
            // const params =
            //     '&params=' + (config.params ? JSON.stringify(config.params) : '');
            // const data =
            //     '&data=' + (config.data ? JSON.stringify(config.data) : '');
            // const requestUrl =
            //     handleUrl(config.baseURL, config.url) +
            //     '&' +
            //     config.method +
            //     params +
            //     data;
            // if (pending[i].u === requestUrl) {
            //     //当当前请求在数组中存在时执行函数体
            //     pending[i].cancelFun(); //执行取消操作
            //     pending.splice(i, 1); //把这条记录从数组中移除
            //     break;
            // }
        // }
    }
    
};
axios.defaults.withCredentials = true;
//添加请求拦截器
axios.interceptors.request.use(
    config => {
        removePending(config); //在一个ajax发送前执行一下取消操作
        config.cancelToken = new cancelToken(cancel => {
            const locationParams = getQsObj();
            if(config.method==='get'){
                if(config.params){
                    if(locationParams&&locationParams['systemType']){
                        Object.assign(config.params,{
                            systemType:locationParams['systemType']
                        });
                    }
                }else{
                    if(locationParams&&locationParams['systemType']){
                        config['params'] = {
                            systemType:locationParams['systemType']
                        };
                    }
                }
            }else{
                if(config.data){
                    if(locationParams&&locationParams['systemType']){
                        if(typeof config.data === 'string'){
                            config.data = JSON.parse(config.data);
                        }
                        Object.assign(config.data,{
                            systemType:locationParams['systemType']
                        });
                    }
                }else{
                    if(locationParams&&locationParams['systemType']){
                        config['data'] = {
                            systemType:locationParams['systemType']
                        };
                    }
                }
            }

            // 这里的ajax标识我是用请求地址&请求方式&请求参数拼接的字符串
            const params =
                '&params=' +
                (config.params ? JSON.stringify(config.params) : '');
            const data =
                '&data=' + (config.data ? JSON.stringify(config.data) : '');
            const requestUrl =
                handleUrl(config.baseURL, config.url) +
                '&' +
                config.method +
                params +
                data;
            pending.push({ u: requestUrl, cancelFun: cancel });
        });
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//添加响应拦截器
axios.interceptors.response.use(
    response => {
        if(response.config){
            removePending(response.config); //在一个ajax响应后再执行一下取消操作，把已经完成的请求从pending中移除
        }
        return response;
    },
    error => {
        if (error.response) {
            return {
                data: {}, //返回一个空对象，防止wrapResponse报错
                status: error.response.status, //返回错误码
            };
        } else {
            return {
                data: { msg: 'cancel' },
            };
        }
    }
);

const defaultOptions = {
    headers: {
        'Content-Type': 'application/json',
    },
    paramsSerializer: function (params) {
        return qs.stringify(params, {
            arrayFormat: 'indices',
        });
    },
    timeout: 1000 * 60,
};

function request(options = {}) {
    const headers = {
        ...defaultOptions.headers,
        ...options.headers,
    };

    // const CancelToken = axios.CancelToken;
    // const source = CancelToken.source();

    const opt = {
        ...defaultOptions,
        ...options,
        baseURL: window.BASE_URL || '',
        headers,
        method: options.method || 'get',
        //cancelToken: source.token,
    };

    const p = axios(opt);
    // 可通过调用p.source.cancel()取消请求
    //p.source = source;
    return p;
}

export function postJSON(url, data, options) {
    let opt = merge(options,{
        url,
        data,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if(localStorage.getItem('shouguancloud_token')){
        opt.url += `?token=${localStorage.getItem('shouguancloud_token')}`;
        opt.data.token = localStorage.getItem('shouguancloud_token');
    }
    // localStorage.getItem('shouguancloud_token')&&(opt.headers.Authorization = localStorage.getItem('shouguancloud_token'));
    return request(opt);
}

export function formUpload(url, data, options) {
    const opt = merge(options,{
        method: 'post',
        url,
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return request(opt);
}

function urlSetToken(opt){
    if(localStorage.getItem('shouguancloud_token')){
        opt.url += `?token=${localStorage.getItem('shouguancloud_token')}`;
        return opt; 
    }
}

export function postForm(url, data, options) {
    let opt = merge(options,{
        method: 'post',
        url,
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        transformRequest: [
            function (data) {
                const ret = qs.stringify(data, {
                    arrayFormat: 'indices',
                });
                return ret;
            },
        ],
    });
    opt = urlSetToken(opt);
    return request(opt);
}

export function getJSON(url, params, options) {
    const opt = {
        ...options,
        url,
        params,
        method: 'get',
    };
    if(localStorage.getItem('shouguancloud_token')){
        opt.params.token = localStorage.getItem('shouguancloud_token');
    }
    return request(opt);
}

export function upload(url, data, options) {
    const opt = {
        ...options,
        method: 'post',
        url,
        data,
    };
    return request(opt);
}

export function del(url, params, options) {
    const opt = {
        ...options,
        url,
        params,
        method: 'delete',
    };

    return request(opt);
}

export function put(url, params, options) {
    const opt = {
        ...options,
        url,
        params,
        method: 'put',
    };

    return request(opt);
}
/**
 * @description 对response进行处理
 * @return {Promise} 经过处理后的Promise
 */
export function wrapResponse(p) {
    return p
        .then(response => {
            if(response.status && response.status === 401){
                localStorage.getItem('shouguancloud_token')&&localStorage.removeItem('shouguancloud_token');
                throw new Error('请授权后访问');
            }
            if (typeof response.data !== 'object') {
                throw new Error('返回格式错误');
            }
            if (
                Number(response.data.code) === 1
            ) {
                return response.data.data||'success';
            } else {
                throw new Error(
                    response.data.msg||
                        '服务器处理错误'
                );
            }
        })
        .catch(e => {
            if (e.response) {
                throw new Error('服务器处理错误');
            } else if (e.request) {
                // 超时
                if (e.code === 'ECONNABORTED') {
                    throw new Error('请求超时错误');
                }
                throw new Error('请求处理错误');
            } else {
                throw e;
            }
        });
}
