import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
const state = {
    screenHeight:0,
    mainUrlPath:'/',//首页
    infoUrlPath:'/info',//新闻列表页
    contactUrlPath:'/contact',//联系我们
    examDate:{},
    examPlace:[],
    advCfdaObj:{},
    advBdaObj:{},
    advVideoObj:{},
    richTextObj:{
        linkUsContent:'',
        baokaotiaojian:'',
        peixundagangchuji:'',
        peixundagangzhongji:'',
        kaoheguifan:'',
        chujicfda:'',
        zhongjicfda:'',
        chujizhengshuyangben:'',
        zhongjizhengshuyangben:'',
    },
    userInfo:{},
    newNews:[],

    // 考试相关
    examInfo:{},
    questionsInfo:[],
    question:'',//当前展示的试题
    answers:{},
    marks:[],
    examType:1, //1正式考试 0模拟考试
    logoImg:'',
    globalConfig: {},
    daohangConfig: {},
};
const mutations = {
    'UPDATE_INFO'(state,data){
        state.richTextObj.linkUsContent = data.link_us.content;
        state.examDate = data.exam_date.content;
        Number(state.userInfo.type)===0?state.examPlace = data.exam_place.content:state.examPlace = data.zj_exam_place.content;
        state.advCfdaObj = data.cfda;
        state.advBdaObj = data.bda;
        state.advVideoObj = data.index_video;
        state.logoImg = data.logo.image
    },
    'CHANGE_STATE'(state,data){
        console.log(data);
        for(const key in data){
            state[key] = data[key];
        }
    },
    'LOGIN_OUT'(state,toast){
        state.userInfo = {},
        localStorage.removeItem('shouguancloud_token');
        toast('您的账号已退出该系统');
        const tem = setTimeout(() => {
            location.href = '/';
            clearTimeout(tem);
        }, 1500);
        
    },
    'UPDATE_RICHCONTENT'(state,data){
        for(const key in data){
            state.richTextObj[key] = data[key];
        }
    },
    'UPDATE_GLOBALCONFIG'(state,data){
        state.globalConfig = data;
        console.log(data,'data');
    },
    'UPDATE_DAOHANGCONFIG'(state,data){
        state.daohangConfig = data;
        console.log(data,'data');
    },
};
const actions = {
    async 'UPDATE_USERINFO'({commit},vueHttp){
        try {
            const res = await vueHttp.get('/api/user/getUser',{token:localStorage.getItem('shouguancloud_token')})
            if(res){
                commit('CHANGE_STATE',{userInfo:res});
                return res;
            }
        } catch (error) {
            console.log(error);
        }
    },
    async 'UPDATE_INDEXCONFIG'({commit},vueHttp){
        try {
            const res = await vueHttp.get('/api/index/getIndexConfig',{token:localStorage.getItem('shouguancloud_token')})
            if(res){
                commit('CHANGE_STATE',{daohangConfig:res});
                return res;
            }
        } catch (error) {
            console.log(error);
        }
    },
    async 'UPDATE_RICHCONTENT'({commit},vueHttp){
        try {
            const res1 = await vueHttp.get('/api/index/getNavNodes',{nodeName:'报考指南'})
            if(res1){
                res1.forEach(item => {
                    console.log(item);
                    if(item.title === '考核规范'){
                        commit('UPDATE_RICHCONTENT',{kaoheguifan:item.content})
                    }
                    if(item.title === '报考条件'){
                        commit('UPDATE_RICHCONTENT',{baokaotiaojian:item.content})
                    }
                })
            }
            const res2 = await vueHttp.get('/api/index/getNavNodes',{nodeName:'培训大纲'})
            if(res2){
                res2.forEach(item => {
                    if(item.title === '中级培训大纲'){
                        commit('UPDATE_RICHCONTENT',{peixundagangzhongji:item.content})
                    }
                    if(item.title === '初级培训大纲'){
                        commit('UPDATE_RICHCONTENT',{peixundagangchuji:item.content})
                    }
                })
            }
        } catch (error) {
            console.log(error);
        }
    },
    async 'SUBMIT_PAPER'({state},vueHttp){
        try {
            const res = await vueHttp.post('/api/exam/addExamPaper',{answers:state.answers,examId:state.examInfo.id,type:state.examType})
            return res;
        } catch (error) {
            console.log(error);
        }
    }
};
const store = new Vuex.Store({
    state,
    actions,
    mutations,
    modules: {
    },
});

export default store;
