var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer_bot"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"row-content"},[_c('div',[_c('ul',_vm._l((_vm.firstArr),function(item,index){return _c('li',{key:index},[(!item.id)?_c('a',{style:({fontWeight:index===0?'bold !important':400})},[_vm._v(_vm._s(item.name))]):_vm._e(),(item.id)?_c('a',{style:({fontWeight:index===0?'bold !important':400}),attrs:{"href":'/course?cateId='+item.id}},[_vm._v(_vm._s(item.name))]):_vm._e()])}),0)]),_c('div',[_c('ul',[_vm._m(0),_vm._l((_vm.daohangConfig.jjfa),function(item,index){return _c('li',{key:index},[_c('a',{staticStyle:{"fontWeight":"400"},on:{"click":function($event){return _vm.goOpen(item.link)}}},[_vm._v(_vm._s(item.name))])])})],2)]),_c('div',[_c('ul',_vm._l((_vm.thirdArr),function(item,index){return _c('li',{key:index},[(!item.href)?_c('a',{style:({fontWeight:index===0?'bold !important':400})},[_vm._v(_vm._s(item.name))]):_vm._e(),(item.href)?_c('a',{style:({fontWeight:index===0?'bold !important':400}),attrs:{"href":item.href}},[_vm._v(_vm._s(item.name))]):_vm._e()])}),0)]),_c('div',[_c('ul',_vm._l((_vm.fourthArr),function(item,index){return _c('li',{key:index},[(!item.href)?_c('a',{style:({fontWeight:index===0?'bold !important':400})},[_vm._v(_vm._s(item.name))]):_vm._e(),(item.href)?_c('a',{style:({fontWeight:index===0?'bold !important':400}),attrs:{"href":item.href}},[_vm._v(_vm._s(item.name))]):_vm._e()])}),0)]),_vm._m(1),_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"footer-line"}),_vm._m(4),_c('div',{staticClass:"copy"},[_vm._v(" 地址：中国北京丰台区南三环西路16号3号楼11层. 邮编：100068 ")]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticStyle:{"fontWeight":"bold"}},[_vm._v("解决方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../assets/shouguanqrcode.png")}}),_c('br'),_c('div',{staticClass:"desc"},[_vm._v("首冠教育微信公众号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../assets/duxueqrcode.png")}}),_c('br'),_c('div',{staticClass:"desc"},[_vm._v("首冠督学公众号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"36px"}},[_vm._v(" 电话：+86 010-64508600"),_c('br'),_c('br'),_vm._v("邮箱:oneplusx@shouguan.cn ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copy"},[_vm._v(" Copyright © 2006-2020 首冠教育, All Rights Reserved. 京公网安备 11011302001876号 "),_c('a',{staticStyle:{"font-size":"13px"},attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("京ICP备2021026270号")]),_vm._v(" 呼叫业务B2-20183766 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://uweb.umeng.com/v1/login.php?siteid=1280277974","target":"_blank"}},[_c('div',{staticClass:"copy"},[_vm._v(" 站长统计 ")])])
}]

export { render, staticRenderFns }